<template>
    <div class="container_con">
        <el-card>
            <div class="con_title"><span></span> 新增</div>
            <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                <el-form-item label="收货人姓名：" prop="title">
                    <el-input v-model="form.title" maxlength="20" placeholder="请输入收货人姓名" style="width:300px" />
                </el-form-item>
                <el-form-item label="手机号：" prop="mobile">
                    <el-input v-model="form.mobile" maxlength="11" placeholder="请输入手机号" style="width:300px" />
                </el-form-item>
                <el-form-item label="省市区：" prop="province">
                    <el-cascader style="width:300px" @change="change" v-model="pid" :options="list"
                        :props="{ expandTrigger: 'hover',checkStrictly: false,emitPath:true,value:'id',label:'title',children:'_child'}"
                        clearable></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址：" prop="detail">
                    <el-input v-model="form.detail" maxlength="100" placeholder="请输入详细地址" style="width:300px" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
    <div class="bottom_btn">
        <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
    </div>
</template>

<script>
    export default {
        name: "addPosition",
        data() {
            return {
                id: '',
                is_ajax: false,
                form: {
                    title:''
                },
                pid:'',
                rules: {
                  title: [
                    { required: true, message: "收货人不能为空",trigger: "blur"}
                  ],
                  mobile: [
                    { required: true, message: "手机号不能为空",trigger: "blur"}
                  ],
                //   province: [
                //     { required: true, message: "省市区不能为空",trigger: "blur"}
                //   ],
                  detail: [
                    { required: true, message: "详细地址为空",trigger: "blur"}
                  ]
                },
                list: []
            };
        },
        created() {
            this.id=this.$route.query.id || '';
            if(this.id){
                this.get_data()
            }
            this.getOptions()
        },
        methods: {
            change(e){
                if(e){
                    this.form.province=e[0];
                    this.form.city=e[1];
                    this.form.district=e[2];
                    this.form.street=e[3];
                }else{
                    this.form.province='';
                    this.form.city='';
                    this.form.district='';
                    this.form.street='';
                }
            },
            // 表单重置
            reset() {
              this.form = {
                title:''
              };
              this.pid=[];
              if(this.$refs['form']){
                this.$refs['form'].clearValidate();
              }
            },
            /** 提交按钮 */
            submitForm: function(form) {
              this.$refs[form].validate((valid)  => {
                if (valid) {
                  this.is_ajax=true;
                  if(form=='form'){
                    this.$httpPost("/backend/ShippingAddress/save", this.form).then((res) => {
                        if (res.status == 200) {
                            if(this.form.id){
                                this.$message.success('更新成功');
                                this.$router.go(-1)
                            }else{
                                this.$message.success('新增成功');
                                // this.reset();
                                this.$router.go(-1)
                            }
                        } else {
                            this.$message.error(res.message);
                        }
                        this.is_ajax=false;
                    }).catch((err) => {
                        console.log(err);
                        this.is_ajax=false;
                    });
                    
                  }
                }
              });
            },
            get_data(){
                this.$httpGet("/backend/ShippingAddress/read", {id:this.id}).then((res) => {
                    if (res.status == 200) {
                        this.form=res.data;
                        this.pid=[res.data.province,res.data.city,res.data.district,res.data.street]
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            getOptions(){
                if(localStorage.area){
                    this.list=JSON.parse(localStorage.area)
                }else{
                   this.$httpGet("/backend/Public/getArea", {}).then((res) => {
                       if (res.status == 200) {
                           localStorage.area=JSON.stringify(res.data);
                           this.list=res.data
                       } else {
                           this.$message.error(res.message);
                       }
                   }).catch((err) => {
                       console.log(err);
                   }); 
                }
                
            }
        }

    };
</script>

<style lang="scss" scoped></style>
